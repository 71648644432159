export const Permission = Object.freeze({
    // route pages
    DashboardView: 'dashboard:dashboard',
    IntegrationsView: 'integrations:view',
    EvidenceView: 'evidences:view',
    AssessmentsView: 'assessments:view',
    IntegrationProfileView: 'integration_profiles:view',
    InternalView: 'internal_audits:view',

    // internal
    InternalCreate: 'internal_audits:create',
    InternalComplete: 'internal_audits:complete',
    InternalDetail: 'internal_audits:detail',
    InternalAudit: 'internal_audits:audit',
    InternalReport: 'internal_audits:report',

    // assessment
    AssessmentCreate: 'assessments:create',
    AssessmentComplete: 'assessments:complete',
    AssessmentDetail: 'assessments:detail',
    AssessmentScan: 'assessments:rescan',
    AssessmentAudit: 'assessments:assess',
    AssessmentReport: 'assessments:report',

    // risk
    RiskView: 'risk:view',

    // Evidence Type Mapping
    EvidenceTypeMappingCreate: 'evidenceTypeMapping:create',
    EvidenceTypeMappingDelete: 'evidenceTypeMapping:delete',

    //Evidence
    EvidenceUpload: 'evidences:upload',
    EvidenceDownload: 'evidences:download',

    // Policy
    PolicyView: 'policies:view',
    PolicyList: 'policies:list',
    PolicyUpload: 'policies:upload',
    PolicyDownload: 'policies:download',

    // integration profile
    IntegrationProfileCreate: 'integration_profiles:create',
    IntegrationProfileDelete: 'integration_profiles:delete',
    IntegrationProfileReport: 'integration_profiles:report',

    // Client
    ClientCreate: 'tenants:create',
    ClientEdit: 'tenants:update',
    ClientView: 'tenants:view',

    // User
    UserView: 'users:view',
    UserCreate: 'users:create',
    UserVerify: 'users:verify',
    UserChangeRole: 'users:role',
    UserReset: 'users:reset',
    UserDisable: 'users:disable',

    // Help ad Support
    SupportView: 'support:view',

    // onboardingPage
    onboardingPageView: 'system:onboarding'
});
export const routePriority = {
    'dashboard:dashboard': '/dashboard',
    'integrations:view': '/integrations',
    'evidences:view': '/evidence',
    'internal_audits:view': '/assessments/internal',
    'assessments:view': '/assessments/manage',
    'risk:view': '/assessments/risk'
};
