/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable unicorn/no-array-for-each */
import { useEffect, useMemo, useState } from 'react';
import IconNotification from './../../assets/svg/icon-notification.svg';
import { useDispatch, useSelector } from 'react-redux';
import useSignalR from '@/hooks/useSignalR';
import './Notification.less';
import { Divider, Dropdown, List, Modal, Progress, Space, Spin } from 'antd';
import StickCard from '../Common/Card/Card';
import StickButton from '@/components/Common/Button/Button';
import {
    addNotification,
    markSeenAllNotifications,
    notificationType,
    seenNotification
} from '@/store/notificationReducer';
import { NotificationMessageType } from '@/components/enum/NotificationMessageType';
import useIsElementVisible from '@/hooks/useIsElementVisible';
import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import reslutGenerated from '@/assets/images/result-generated.png';

const notificationTypeIcons = {
    Information: <InfoCircleFilled style={{ color: '#1677ff' }} />,
    Warning: <InfoCircleFilled style={{ color: '#faad14' }} />,
    Error: <CloseCircleFilled style={{ color: '#ff4d4f' }} />,
    Success: <CheckCircleFilled style={{ color: '#52c41a' }} />
};

const BellNotification = () => {
    const notifications = useSelector((state) => state.notifications.notifications);
    const [modalnfo, setModalnfo] = useState({ visible: false, info: '' });
    const dispatch = useDispatch();
    const { isConnected, onNotification } = useSignalR();
    const [notificationList, setNotificationList] = useState([]);
    const [visibleElements, observe] = useIsElementVisible({
        root: document.querySelector('.notification-card-wrapper'),
        rootMargin: '0px',
        threshold: 1
    });
    //listen to server for notification
    useEffect(() => {
        if (isConnected) {
            const unsubscribe = onNotification('NotificationFromServer', (notification, data) => {
                const type = data.notificationType;
                switch (type) {
                    case NotificationMessageType.Job: {
                        const jobId = data.jobId;
                        if (!jobId) {
                            return;
                        }
                        dispatch(
                            addNotification({
                                type: notificationType.BELL,
                                jobId: jobId,
                                progress: Math.round(data.progress),
                                message: data?.message,
                                notificationType: NotificationMessageType.Job
                            })
                        );
                        break;
                    }
                    case NotificationMessageType.Basic: {
                        dispatch(
                            addNotification({
                                type: notificationType.BELL,
                                message: data?.message,
                                notificationType: NotificationMessageType.Basic,
                                icon: data?.label
                            })
                        );
                        break;
                    }
                    case NotificationMessageType.Refresh: {
                        const entityType = data?.entityType;
                        const id = data?.id;
                        if (!entityType || !id) {
                            return;
                        }
                        setModalnfo({
                            visible: true,
                            info: data?.message
                        });

                        break;
                    }
                    case NotificationMessageType.ScanProgress: {
                        const scanId = data.scanId;
                        if (!scanId) {
                            return;
                        }
                        dispatch(
                            addNotification({
                                type: notificationType.BELL,
                                scanId: scanId,
                                message: data?.message,
                                progress: data?.progress,
                                showSpinner: data?.showSpinner,
                                notificationType: NotificationMessageType.ScanProgress
                            })
                        );
                        break;
                    }
                    default: {
                        // eslint-disable-next-line no-console
                        console.warn(`Unknown notification type: ${type}`);
                    }
                }
            });

            return () => {
                if (unsubscribe) {
                    unsubscribe();
                }
            };
        }
    }, [isConnected, onNotification]);
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const handleOk = () => {
        if (
            location.pathname == '/dashboard' ||
            location.pathname == '/assessments/manage' ||
            location.pathname == '/assessments/internal'
        ) {
            window.location.reload();
        }
        setModalnfo({
            visible: false,
            info: ''
        });
    };

    //mark notifications as seen when they are visible
    useEffect(() => {
        visibleElements.forEach((isVisible, id) => {
            if (isVisible) {
                dispatch(seenNotification(id));
            }
        });
    }, [visibleElements, dispatch]);

    const handleDropdownOpen = () => {
        //observe seen notifications
        notifications.forEach((notification) => {
            const element = document.querySelector(`[data-id="${notification.id}"]`);
            if (element) {
                observe(element);
            }
        });
    };

    // Update notification list when notifications change
    useEffect(() => {
        if (notifications.length > 0) {
            const bellNotifications = notifications.filter(
                (notification) => notification.type === notificationType.BELL
            );
            setNotificationList(bellNotifications);
        }
    }, [notifications]);

    const handleMarkAllNotificationAsSeen = () => {
        dispatch(markSeenAllNotifications());
    };

    const isNotification = useMemo(
        () => notificationList.some((notification) => !notification.seen),
        [notificationList]
    );

    const renderNotificationContent = (notification) => {
        switch (notification.notificationType) {
            case NotificationMessageType.Job: {
                return (
                    <div>
                        <div>{notification?.message}</div>
                        {notification?.progress >= 0 && (
                            <Progress
                                percent={notification.progress}
                                size='small'
                                status={notification.progress < 100 ? 'active' : 'success'}
                            />
                        )}
                    </div>
                );
            }
            case NotificationMessageType.ScanProgress: {
                return (
                    <div>
                        <div>{notification?.message}</div>
                        <Space>
                            <Spin spinning={notification?.showSpinner} />
                            <div>{notification?.progress}</div>
                        </Space>
                    </div>
                );
            }
            case NotificationMessageType.Basic: {
                const icon = notificationTypeIcons[notification?.icon];
                return (
                    <div>
                        {icon}
                        <span style={{ marginLeft: '5px' }}>{notification?.message}</span>
                    </div>
                );
            }
            case NotificationMessageType.Refresh: {
                return <div>{notification?.message}</div>;
            }
            default: {
                return <div>{notification?.message}</div>;
            }
        }
    };

    const notificationDropDownUi = () => {
        return (
            <StickCard className='notification-card-wrapper'>
                <StickCard.Title>Notifications</StickCard.Title>
                <Divider />
                {isNotification ? (
                    <div
                        onClick={handleMarkAllNotificationAsSeen}
                        className='mark-all-read'>
                        Mark all as read
                    </div>
                ) : null}
                <List
                    dataSource={notificationList}
                    locale={{ emptyText: 'No notifications available' }}
                    renderItem={(item) => (
                        <>
                            <List.Item
                                data-id={item.id}
                                key={item.id}>
                                {renderNotificationContent(item)}
                                {item.seen ? null : <div className='red-dot'>🔴</div>}
                            </List.Item>
                        </>
                    )}
                />
            </StickCard>
        );
    };

    return (
        <div className='bell-notification'>
            <Dropdown
                overlayClassName='notification-dropdown'
                onOpenChange={handleDropdownOpen}
                trigger={['click']}
                dropdownRender={() => {
                    return notificationDropDownUi();
                }}>
                <div className={`notification-wrapper ${isNotification ? 'show-dot' : ''}`}>
                    <IconNotification />
                </div>
            </Dropdown>
            <Modal
                title='Congratulations'
                open={modalnfo.visible}
                onOk={handleOk}
                maskClosable={false}
                onCancel={handleOk}
                width={750}
                footer={null}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '20px',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <img
                        src={reslutGenerated}
                        width={250}
                        alt=''
                        srcSet=''
                    />
                    <div style={{ width: '90%', marginTop: '20px' }}>{modalnfo.info}</div>
                    <StickButton
                        style={{ width: '100%', marginTop: '20px' }}
                        type={'primary'}
                        onClick={handleOk}>
                        OK
                    </StickButton>
                </div>
            </Modal>
        </div>
    );
};

export default BellNotification;
