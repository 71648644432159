import { useCallback, useEffect, useRef, useState } from 'react';
//hook to get if an element is visible to user
const useIsElementVisible = (options = {}) => {
    const [visibleElements, setVisibleElements] = useState(new Map());
    const observerRef = useRef(null);

    const observerCallback = useCallback((entries) => {
        const newVisibleElements = new Map();
        for (const entry of entries) {
            newVisibleElements.set(entry.target.dataset.id, entry.isIntersecting);
        }

        setVisibleElements((prev) => new Map([...prev, ...newVisibleElements]));
    }, []);

    const observe = useCallback((element) => {
        if (element) {
            observerRef.current.observe(element);
        }
    }, []);

    useEffect(() => {
        if (observerRef.current) {
            observerRef.current.disconnect();
        }
        observerRef.current = new IntersectionObserver(observerCallback, options);
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [observerCallback, options]);

    const unobserve = useCallback((element) => {
        if (element) {
            observerRef.current.unobserve(element);
        }
    }, []);

    return [visibleElements, observe, unobserve];
};

export default useIsElementVisible;
