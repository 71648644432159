import { useSelector } from 'react-redux';
import { Form, Modal, Result, Select, Spin } from 'antd';
import { AxiosError } from 'axios';
import { useFetchUsersOrganizations } from '../../../api/auth0';
import StickButton from '../Button/Button';

const { Option } = Select;

const ErrorMessageComponent = ({ error, reloadClients }) => {
    let subTitle = 'There was an issue loading the clients. Please try again later.';
    if (error instanceof AxiosError) {
        switch (error.response?.status) {
            case 403: {
                subTitle =
                    'There was an issue loading the clients. You do not have permission to view this record. Please check your information and try again later.';
                break;
            }
            default: {
                break;
            }
        }
    }
    return (
        <Result
            status='error'
            title='Failed to Load Clients'
            subTitle={subTitle}
            extra={[
                <StickButton
                    key='retry'
                    onClick={reloadClients}>
                    Retry
                </StickButton>
            ]}
        />
    );
};

const ClientSwitcherComponent = ({ open, onSelect, onCancel }) => {
    const [form] = Form.useForm();
    const { selectedOrganization, userId } = useSelector((state) => state.user);
    const { data: tenants, error, isLoading, refetch } = useFetchUsersOrganizations(open, userId);

    return (
        <Modal
            open={open}
            title='Switch Client'
            okText='Switch'
            cancelText='Cancel'
            onCancel={onCancel}
            okButtonProps={{
                disabled: error !== null
            }}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        onSelect(values);
                        localStorage.setItem('org_id', values.client);
                    })
                    .catch((_error) => {});
            }}>
            {error && (
                <ErrorMessageComponent
                    reloadClients={refetch}
                    onCancel={onCancel}
                />
            )}
            {!error && (
                <Form
                    form={form}
                    layout='vertical'
                    name='form_in_modal'
                    initialValues={{
                        client: selectedOrganization
                    }}>
                    <Form.Item
                        name='client'
                        label='Client'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a client'
                            }
                        ]}>
                        <Select placeholder='Select a client'>
                            {isLoading ? (
                                // Show a loading indicator when data is still loading.
                                <Option
                                    disabled
                                    key='loading'
                                    value='loading'>
                                    <Spin size='small' /> Loading clients...
                                </Option>
                            ) : (
                                // Render the framework options once data is loaded.
                                tenants?.map((tenant) => (
                                    <Option
                                        key={tenant.identifier}
                                        value={tenant.identifier}>
                                        {tenant.name}
                                    </Option>
                                ))
                            )}
                        </Select>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default ClientSwitcherComponent;
