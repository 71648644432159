import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Dropdown, Layout } from 'antd';
import { LogoutOutlined, UserSwitchOutlined } from '@ant-design/icons';
// eslint-disable-next-line no-unused-vars
import { ClientSwitcherComponent } from '../Common';
import BackPageComponent from '../BackPage/BackPage';
// eslint-disable-next-line no-unused-vars
import 'flag-icons/css/flag-icons.min.css';
import StickButton from '../Common/Button/Button';
import BellNotification from '../BellNotification/BellNotification';

const { Header } = Layout;

// eslint-disable-next-line no-unused-vars
const AppHeader = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { organization } = useSelector((state) => state.user);
    const [openClientSwitcher, setClientSwitcherOpen] = useState(false);
    const navigate = useNavigate();
    const { loginWithRedirect, logout, user } = useAuth0();

    const onActionClick = async (action) => {
        switch (action) {
            case 'help': {
                return;
            }

            case 'logout': {
                logout({ logoutParams: { returnTo: window.location.origin } });
                return;
            }

            case 'profile': {
                navigate('/account/profile');
                return;
            }

            case 'switchclient': {
                setClientSwitcherOpen(true);
            }
        }
    };

    const handleCopyAccessToken = async () => {
        const accessToken = await getAccessTokenSilently();
        const tokenWithBearer = `Bearer ${accessToken}`;
        try {
            await navigator.clipboard.writeText(tokenWithBearer);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Unable to copy Access Token to clipboard', error);
        }
    };

    const onSelectClient = async (values) => {
        setClientSwitcherOpen(false);

        if (organization !== values.client) {
            await logout({ openUrl: false });
            await loginWithRedirect({
                authorizationParams: {
                    organization: values.client
                }
            });
        }

        localStorage.removeItem('guide_passed');
    };

    return (
        <Header
            className='layout-page-header'
            style={{ backgroundColor: '#F7EAE5' }}>
            <div
                style={{ padding: '0px 50px', justifyContent: 'space-between' }}
                className='layout-page-header-main'>
                <div style={{ display: 'flex' }}>
                    {location.pathname.includes('assessment') ? <BackPageComponent /> : null}
                </div>
                <div style={{ display: 'flex' }}>
                    {import.meta.env.MODE == 'development' && window.location.hostname == 'localhost' ? (
                        <StickButton
                            type={'outlined'}
                            onClick={() => handleCopyAccessToken()}>
                            Copy AccessToken
                        </StickButton>
                    ) : null}
                    <div style={{ marginLeft: '20px' }}>
                        <BellNotification />
                    </div>
                    <div className='actions'>
                        <Dropdown
                            menu={{
                                items: [
                                    //{
                                    //    key: '1',
                                    //    icon: <UserOutlined />,
                                    //    label: <span onClick={() => onActionClick('profile')}>Profile</span>
                                    //},
                                    {
                                        key: '2',
                                        icon: <UserSwitchOutlined />,
                                        label: <span onClick={() => onActionClick('switchclient')}>Switch Clients</span>
                                    },
                                    {
                                        key: '3',
                                        icon: <LogoutOutlined />,
                                        label: <span onClick={() => onActionClick('logout')}>Logout</span>
                                    }
                                ]
                            }}>
                            <span className='user-action'>
                                <Avatar
                                    shape='square'
                                    src={user?.picture}
                                    className='user-avator'
                                    alt='avator'
                                />
                            </span>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <ClientSwitcherComponent
                open={openClientSwitcher}
                onSelect={onSelectClient}
                onCancel={() => {
                    setClientSwitcherOpen(false);
                }}
            />
        </Header>
    );
};

export default AppHeader;
