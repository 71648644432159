/* eslint-disable unicorn/prevent-abbreviations */
import { useCallback, useEffect, useRef, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { useAuth0 } from '@auth0/auth0-react';
import config from '@/config';

const useSignalR = () => {
    const connectionRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const signalREndpoint =
        config.mode === 'development' ? 'https://localhost:5010/notifications' : `${config.baseApiUrl}/notifications`;

    useEffect(() => {
        const setupConnection = async () => {
            try {
                const token = await getAccessTokenSilently();

                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(signalREndpoint, {
                        accessTokenFactory: () => token
                    })
                    .withServerTimeout(60000)
                    .withKeepAliveInterval(30000)
                    .withAutomaticReconnect()
                    .configureLogging(signalR.LogLevel.Information)
                    .build();

                connectionRef.current = connection;

                const startConnection = async () => {
                    try {
                        await connection.start();
                        setIsConnected(true);
                        console.log('SignalR Connected.');
                    } catch (err) {
                        console.error('SignalR Connection Error:', err);
                    }
                };

                connection.onreconnected(() => {
                    setIsConnected(true);
                    console.log('SignalR Reconnected.');
                });

                connection.onclose(() => {
                    setIsConnected(false);
                    console.log('SignalR Disconnected.');
                });

                startConnection();
            } catch (error) {
                console.error('Error during SignalR setup:', error);
            }
        };
        setupConnection();

        return () => {
            if (connectionRef.current) {
                if (connectionRef.current.state === signalR.HubConnectionState.Connected) {
                    connectionRef.current.stop();
                }
                connectionRef.current = null;
            }
        };
    }, [getAccessTokenSilently, signalREndpoint]);

    const sendNotification = async (notification) => {
        if (connectionRef.current) {
            try {
                await connectionRef.current.invoke('SendNotification', notification);
            } catch (error) {
                console.error('SignalR Send Error:', error);
            }
        }
    };

    const onNotification = useCallback((eventName, callback) => {
        if (connectionRef.current) {
            const handler = (...args) => callback(...args);

            connectionRef.current.on(eventName, handler);

            return () => {
                connectionRef.current?.off(eventName, handler);
            };
        }
    }, []);

    return { isConnected, sendNotification, onNotification };
};

export default useSignalR;
