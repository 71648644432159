/* eslint-disable unicorn/prevent-abbreviations */
import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from '@/components/enum/Permission';
const PolicyDetails = lazy(() => import('../features/policy/page/PolicyDetails/PolicyDetails'));

const Login = lazy(() => import('../pages/Login'));
const OnboardingPage = lazy(() => import('../pages/Onboarding/page/index'));
const WelcomeEvidencePage = lazy(() => import('../pages/Onboarding/page/welcomeEvidencePage'));
const Layout = lazy(() => import('../layouts/index'));
const Dashboard = lazy(() => import('../features/dashboard/page'));
const InternalAuditsPage = lazy(() => import('../features/internal-audit/pages/InternalAudits'));
const InternalPage = lazy(() => import('../features/internal-audit/pages/InternalAudit'));
const DevDiag = lazy(() => import('../pages/DevDiag'));
const CreateAssessmentPage = lazy(() => import('../features/maturity-assessment/pages/CreateAssessment'));
const AssessmentsPage = lazy(() => import('../features/maturity-assessment/pages/Assessments'));
const AssessmentPage = lazy(() => import('../features/maturity-assessment/pages/Assessment'));
const ClientsPage = lazy(() => import('../features/clients/pages/Clients'));
const TenantManagementLayoutPage = lazy(() => import('../features/tenant-management/pages/Layout'));
const ClientPage = lazy(() => import('../features/clients/pages/Client'));
const CreateClientPage = lazy(() => import('../features/clients/pages/CreateClient'));
const UnauthorizedPage = lazy(() => import('../pages/Unauthorized'));
const NotFoundPage = lazy(() => import('../pages/NotFound'));
const AddIntegrationsAccountLayoutPage = lazy(
    () => import('../features/tenant-management/pages/AddIntegrationsAccount')
);
const Evidence = lazy(() => import('../features/evidence/page/index'));
const Policy = lazy(() => import('../features/policy/page/index'));
const EvidenceTypeListView = lazy(
    () => import('../features/evidence/components/EvidenceTypeListView/EvidenceTypeListView')
);
const UserManagementPage = lazy(() => import('../features/settings/user-management/page/index'));
const ConditionalNavigate = lazy(() => import('../pages/ConditionalNavigate/ConditionalNavigate'));
const RiskPage = lazy(() => import('../features/risk/pages/Risk/index'));
const RiskDetailsPage = lazy(() => import('../features/risk/pages/RiskDetails/index'));
const CreateRisk = lazy(() => import('@/features/risk/pages/CreateRisk/CreateRisk'));

const routeList = [
    {
        path: 'login',
        name: 'Login',
        element: <Login />,
        meta: {
            requiresAuth: false,
            hideInMenu: true,
            showInBreadcrumb: false
        }
    },
    {
        path: 'onboardingPage',
        name: 'OnboardingPage',
        element: <OnboardingPage />,
        meta: {
            requiresAuth: false,
            hideInMenu: true,
            showInBreadcrumb: false
        }
    },
    {
        path: 'welcomeEvidencePage',
        name: 'WelcomeEvidencePage',
        element: <WelcomeEvidencePage />,
        meta: {
            requiresAuth: false,
            hideInMenu: true,
            showInBreadcrumb: false
        }
    },
    {
        path: '',
        name: 'Home',
        element: <Layout />,
        meta: {
            requiresAuth: true,
            hideInMenu: true,
            showChildrenInMenu: true
        },
        children: [
            //Make dashboard the default route after they are authenticated
            {
                path: '',
                name: 'RedirectToDashboard',
                element: <ConditionalNavigate />,
                meta: {
                    hideInMenu: true,
                    showInBreadcrumb: false
                }
            },
            {
                path: 'dashboard',
                name: 'Dashboard',
                title: 'Dashboard',
                element: <Dashboard />,
                icon: 'dashboard',
                permission: Permission.DashboardView
            },
            {
                path: 'integrations',
                name: 'Integrations',
                title: 'Integrations',
                icon: 'integrations',
                element: <TenantManagementLayoutPage />,
                permission: Permission.IntegrationsView
            },
            {
                path: 'evidence',
                name: 'Evidence',
                title: 'Evidence',
                icon: 'evidence',
                element: <Evidence />,
                permission: Permission.EvidenceView
            },

            {
                path: 'addIntegrationsAccount',
                name: 'AddIntegrationsAccount',
                title: 'Add Integrations Account',
                element: <AddIntegrationsAccountLayoutPage />,
                permission: Permission.IntegrationProfileView,
                meta: {
                    hideInMenu: true,
                    showInBreadcrumb: false
                }
            },
            {
                path: 'evidenceTypeList',
                name: 'EvidenceTypeListView',
                title: '',
                element: <EvidenceTypeListView />,
                permission: Permission.EvidenceView,
                meta: {
                    hideInMenu: true,
                    showInBreadcrumb: false
                }
            },
            // {
            //     path: 'tenantmanagement',
            //     name: 'Tenant Management',
            //     title: 'Tenant Management',
            //     icon: 'integrations',
            //     element: <TenantManagementLayoutPage />,
            //     children: [
            //         {
            //             path: '',
            //             name: 'ProfileRegistration',
            //             title: 'Profile Registration',
            //             element: <ProfileRegistrationPage />
            //         }
            //     ]
            // },
            {
                path: 'assessments',
                name: 'Assessments',
                icon: 'assessment',
                title: 'Assessments',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        name: 'RedirectToManageAssessments',
                        permission: Permission.AssessmentsView,
                        element: <Navigate to='manage' />,
                        meta: {
                            hideInMenu: true,
                            showInBreadcrumb: false
                        }
                    },
                    {
                        path: 'manage',
                        name: 'ManageAssessments',
                        title: 'Manage Assessments',
                        element: <Outlet />,
                        permission: Permission.AssessmentsView,
                        meta: {
                            hideInMenu: false,
                            showInBreadcrumb: true,
                            showChildrenInMenu: false
                        },
                        children: [
                            {
                                path: '',
                                name: 'ManageAssessments',
                                title: 'Manage Assessments',
                                permission: Permission.AssessmentsView,
                                element: <AssessmentsPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: false
                                }
                            },
                            {
                                path: 'assessment/:id',
                                name: 'ViewAssessment',
                                permission: Permission.AssessmentDetail,
                                title: 'View Assessment',
                                element: <AssessmentPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: true
                                }
                            }
                        ]
                    },

                    {
                        path: 'create',
                        name: 'CreateAssessment',
                        title: 'Create Assessment',
                        element: <CreateAssessmentPage />,
                        meta: {
                            hideInMenu: true,
                            showInBreadcrumb: false,
                            currentSelected: 'ManageAssessments|InternalAudits'
                        }
                    },
                    {
                        path: 'create-risk',
                        name: 'CreateRisk',
                        title: 'Create Risk',
                        element: <CreateRisk />,
                        meta: {
                            hideInMenu: true,
                            showInBreadcrumb: false,
                            currentSelected: 'Risk'
                        }
                    },
                    {
                        path: 'risk',
                        name: 'Risk',
                        title: 'Risk',
                        element: <Outlet />,
                        permission: Permission.AssessmentsView,
                        meta: {
                            hideInMenu: false,
                            showInBreadcrumb: false,
                            showChildrenInMenu: false
                        },
                        children: [
                            {
                                path: '',
                                name: 'RiskPage',
                                title: 'Risk List',
                                element: <RiskPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: false
                                }
                            },
                            {
                                path: 'details/:riskId',
                                name: 'RiskDetailsPage',
                                title: 'Risk List',
                                element: <RiskDetailsPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: false
                                }
                            },
                            {
                                path: '',
                                name: 'RiskPage',
                                title: 'Risk List',
                                permission: Permission.AssessmentsView,
                                element: <RiskPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: false
                                }
                            }
                        ]
                    },
                    {
                        path: 'policy',
                        name: 'Policy',
                        title: 'Policy',
                        element: <Outlet />,
                        permission: Permission.PolicyView,
                        children: [
                            {
                                path: '',
                                name: 'Policy',
                                title: 'Policy',
                                permission: Permission.InternalView,
                                element: <Policy />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: false
                                }
                            },
                            {
                                path: 'details/:policyId',
                                name: 'PolicyDetails',
                                title: 'Policy Details',
                                element: <PolicyDetails />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: true
                                }
                            }
                        ]
                    },
                    {
                        path: 'internal',
                        name: 'InternalAudits',
                        title: 'Internal Audits',
                        element: <Outlet />,
                        permission: Permission.InternalView,
                        meta: {
                            hideInMenu: false,
                            showInBreadcrumb: true,
                            showChildrenInMenu: false
                        },
                        children: [
                            {
                                path: '',
                                name: 'InternalAuditsPage',
                                title: 'Internal Audits',
                                permission: Permission.InternalView,
                                element: <InternalAuditsPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: false
                                }
                            },
                            {
                                path: 'internalaudit/:id',
                                name: 'ViewInternalAudits',
                                title: 'View InternalAudits',
                                permission: Permission.InternalDetail,
                                element: <InternalPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: true
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'clients',
                name: 'Settings',
                title: 'Settings',
                element: <Outlet />,
                icon: 'settings',
                permission: Permission.SupportView,
                children: [
                    {
                        path: 'user-management',
                        name: 'UserManagement',
                        title: 'User Management ',
                        permission: Permission.UserView,
                        element: <UserManagementPage />
                    },
                    {
                        path: '',
                        name: 'RedirectToManageClients',
                        element: <Navigate to='manage' />,
                        meta: {
                            hideInMenu: true,
                            showInBreadcrumb: false
                        }
                    },
                    {
                        path: 'manage',
                        name: 'ClientManagement',
                        title: 'Client Management',
                        element: <Outlet />,
                        permission: Permission.ClientView,
                        meta: {
                            hideInMenu: false,
                            showInBreadcrumb: true,
                            showChildrenInMenu: true
                        },
                        children: [
                            {
                                path: '',
                                name: 'ClientManagement ',
                                title: 'Client Management ',
                                element: <ClientsPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: false
                                }
                            },
                            {
                                path: 'client/:id',
                                name: 'ViewClient',
                                title: 'View Client',
                                element: <ClientPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: true
                                }
                            },
                            {
                                path: 'client/:id',
                                name: 'ViewClient',
                                title: 'View Client',
                                element: <ClientPage />,
                                meta: {
                                    hideInMenu: true,
                                    showInBreadcrumb: true
                                }
                            }
                        ]
                    },
                    {
                        path: 'HelpAndSupport',
                        name: 'HelpAndSupport',
                        title: 'Help and Support',
                        permission: Permission.SupportView
                    },
                    {
                        path: 'create-client',
                        name: 'CreateClient',
                        title: 'Create Client',
                        element: <CreateClientPage />,
                        meta: {
                            hideInMenu: true,
                            showInBreadcrumb: true,
                            currentSelected: 'ClientManagement'
                        }
                    }
                ]
            },
            {
                path: 'dev-diag',
                name: 'Developer Diagnostics',
                title: 'Developer Diagnostics',
                element: <DevDiag />,
                permission: 'diagnostics:view',
                icon: 'developer'
            }
        ]
    },
    {
        path: 'unauthorized',
        name: 'Unauthorized',
        element: <UnauthorizedPage />,
        meta: {
            requiresAuth: false,
            hideInMenu: true
        }
    },
    {
        path: '*',
        name: 'NotFound',
        element: <NotFoundPage />,
        meta: {
            requiresAuth: false,
            hideInMenu: true
        }
    }
];

export default routeList;
